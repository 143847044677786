import banjosApi from "../api/banjosApi";
import { version } from "./../../package.json";
import * as Sentry from "@sentry/react";

export const useLogger = () => {
  const deviceInfo = JSON.parse(localStorage.getItem("DeviceInfo"));
  const LogError = (type, error) => {
    let _err =
      deviceInfo &&
      `Version : ${version} |Store : ${deviceInfo.branchId} | Device :${deviceInfo.deviceId} | Group : ${deviceInfo.groupId} | Info : `;
    _err = _err + error;
    banjosApi
      .post("default/LogError", { type: type, logInfo: _err })
      .then((res) => {
        //console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const LogInformation = (info) => {
    Sentry.captureMessage(info, Sentry.Severity.Info);
  };
  const LogToExternal = (type, data) => {
    if (type === "error") {
      Sentry.captureException(data);
    } else if (type === "info") {
      Sentry.captureMessage(data, Sentry.Severity.Info);
    }
  };
  return { LogError, LogInformation, LogToExternal };
};
