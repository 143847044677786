import React, { useEffect, useState } from "react";
import { useImmer } from "use-immer";
import banjosApi from "../api/banjosApi";
import { getTenantId } from "../helper/Common";

export const useSettings = () => {
  const [isBusy, setBusy] = useState(false);
  const [settings, setSettings] = useImmer({
    businessName: "",
    logo: "",
    logoFile: null,
    placeholderHorizontal: "",
    placeholderHorizontalFile: null,
    placeholderVertical: "",
    placeholderVerticalFile: null,
  });

  useEffect(() => {
    if (getTenantId()) {
      setBusy(true);
      banjosApi
        .get("settings")
        .then((res) => {
          setSettings((draft) => {
            return { ...draft, ...res.data };
          });
          setBusy(false);
        })
        .catch((err) => {
          setBusy(false);
          // console.log(err);
        });
    }

    return () => {};
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    setSettings((draft) => {
      draft[name] = value;
    });
  };

  const onFileChange = (e, name) => {
    // console.log(e.target.files[0]);
    setSettings((draft) => {
      draft[name] = e.target.files[0];
    });
  };

  const onSave = () => {
    setBusy(true);
    const formData = new FormData();
    for (var key in settings) {
      // console.log(key, settings[key]);
      formData.append(key, settings[key]);
    }
    banjosApi
      .post("settings", formData)
      .then((res) => {
        setBusy(false);
      })
      .catch((err) => {
        setBusy(false);
      });
  };

  return { settings, onChange, onSave, onFileChange, isBusy };
};
