export const randomInteger = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const randomColor = () => {
  var colors = [
    "#BD996C",
    "#808957",
    "#B04222",
    "#A23852",
    "#4F3E3B",
    "#D7A946",
    "#AF4124",
    "#D7A946",
  ];

  return colors[Math.floor(Math.random() * 8)];
};

export const hasKey = (object, key) => {
  return Object.keys(object).some(function (member) {
    return ~member.indexOf(key);
  });
};

export const DeviceInfo = () => {
  const info = localStorage.getItem("DeviceInfo");
  if (info) {
    return JSON.parse(info);
  } else {
    return { deviceId: "NotFound" };
  }
};

export const CalFileSize = (size) => {
  try {
    if (+size >= 1024) {
      return `${(+size / 1024).toFixed(2)}Mb`;
    } else {
      return `${size} Kb`;
    }
  } catch (error) {
    return `N/A`;
  }
};

export const getTenantId = () => {
  let tenantId = "";
  tenantId = localStorage.getItem("tid");

  if (tenantId) return tenantId;

  // var domain = /:\/\/([^\/]+)/.exec(window.location.href)[1];
  tenantId = window.location.host.split(".")[1]
    ? window.location.host.split(".")[0]
    : "";

  if (tenantId === "splayer" || tenantId === "player") tenantId = "";

  return tenantId;
};
