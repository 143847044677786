import React, { useEffect, useState } from "react";
import { useImmer } from "use-immer";
import banjosApi from "../api/banjosApi";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useLogger } from "./useLogger";
import { getTenantId } from "../helper/Common";
export const useBranch = (load, branchId) => {
  const { addToast } = useToasts();
  const { LogToExternal } = useLogger();
  const [branch, setBranch] = useImmer();
  const [state, setState] = useImmer({
    branchId: 0,
    name: "",
    address: "",
    city: "",
    province: "",
    phone: "",
    timeZone: "",
    accessCode: "",
  });

  const [isBusy, setBusy] = useState(false);
  const history = useHistory();
  const tid = getTenantId();

  const onLoad = () => {
    if (tid) {
      setBusy(true);
      banjosApi
        .get("branch")
        .then((res) => {
          setBranch((draft) => res.data);
          setBusy(false);
        })
        .catch((err) => {
          addToast(
            err.response ? err.response.data.message : "Unable to connect",
            {
              appearance: "error",
            }
          );
          setBusy(false);
        });
    }
  };

  const onSave = (state) => {
    setBusy(true);
    banjosApi
      .post("branch", state)
      .then((res) => {
        history.push("/admin/branch");
      })
      .catch((err) => {
        addToast(
          err.response ? err.response.data.message : "Unable to connect",
          {
            appearance: "error",
          }
        );
        setBusy(false);
      });
  };

  const onUpdate = (id, state) => {
    setBusy(true);
    banjosApi
      .put(`branch/${id}`, state)
      .then((res) => {
        setBusy(false);
        history.push("/admin/branch");
      })
      .catch((err) => {
        addToast(
          err.response ? err.response.data.message : "Unable to connect",
          {
            appearance: "error",
          }
        );
        setBusy(false);
      });
  };

  const onDelete = (id) => {
    setBusy(true);
    banjosApi
      .delete(`branch/${id}`)
      .then((res) => {
        setBusy(false);
        setBranch((draft) => {
          return draft.filter((c) => c.branchId !== id);
        });
      })
      .catch((err) => {
        addToast(
          err.response ? err.response.data.message : "Unable to connect",
          {
            appearance: "error",
          }
        );
        setBusy(false);
      });
  };

  useEffect(() => {
    if (load) {
      onLoad();
    }
  }, []);

  useEffect(() => {
    if (branchId > 0) {
      setBusy(true);
      banjosApi
        .get(`branch/${branchId}`)
        .then((res) => {
          setState((draft) => res.data);
          setBusy(false);
        })
        .catch((err) => {
          addToast(
            err.response ? err.response.data.message : "Unable to connect",
            {
              appearance: "error",
            }
          );
          setBusy(false);
        });
    }
  }, [branchId]);

  return {
    branch,
    setBranch,
    onSave,
    onUpdate,
    onDelete,
    isBusy,
    state,
    setState,
  };
};
