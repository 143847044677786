import axios from "axios";
import { getTenantId } from "../helper/Common";
import { API_ENDPOINT } from "./api-config";
const banjosApi = axios.create({
  baseURL: API_ENDPOINT,
  withCredentials: true,
});

banjosApi.interceptors.request.use(
  (request) => {
    const localData = JSON.parse(localStorage.getItem("localData"));
    const tid = getTenantId();
    request.headers["Authorization"] =
      "Bearer " + (localData && localData.token);
    if (tid) {
      request.headers["X-TENANT"] = tid;
    }
    return request;
  },
  (error) => {
    Promise.reject(error);
  }
);

banjosApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    // const originalRequest = err.config;
    // if (err.response.status === 401 && !originalRequest._retry) {
    //   originalRequest._retry = true;
    //   //await onRefresh();
    //   const localData = JSON.parse(localStorage.getItem("globaldata"));
    //   axios.defaults.headers.common["Authorization"] =
    //     "Bearer " + localData.token;
    //   return banjosApi(originalRequest);
    // } else

    if (err.response && err.response.status === 401) {
      localStorage.removeItem("localData");
      window.location.href = "/admin";
    }
    return Promise.reject(err);
  }
);

export default banjosApi;
