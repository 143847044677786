import React from "react";
import info from "../../../package.json";
export const Info = () => {
  return (
    <div>
      <label>
        {process.env.NODE_ENV}:{info.version}
      </label>
    </div>
  );
};
