import React, { useEffect, useState } from "react";
import { useImmer } from "use-immer";
import banjosApi from "../api/banjosApi";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

export const useDevice = (load, deviceId, branchId) => {
  const { addToast } = useToasts();
  const [device, setDevice] = useImmer([]);
  const [state, setState] = useImmer({
    deviceId: 0,
    branchId: "",
    groupId: "",
    code: "",
    name: "",
    description: "",
    orientation: "",
    deviceStatus: 0,
    accessToken: "",
  });

  const [isBusy, setBusy] = useState(false);
  const history = useHistory();

  const onLoad = () => {
    setBusy(true);
    try {
      banjosApi
        .get("device")
        .then((res) => {
          setDevice((draft) => res.data);
          setBusy(false);
        })
        .catch((err) => {
          addToast(
            err.response ? err.response.data.message : "Unable to connect",
            {
              appearance: "error",
            }
          );
          setBusy(false);
        });
    } catch (error) {
      addToast(error, {
        appearance: "error",
      });
    }
  };

  const onSave = (path) => {
    setBusy(true);
    banjosApi
      .post("device", state)
      .then((res) => {
        setState((draft) => {
          draft.deviceId = res.data.deviceId;
        });
        addToast("Device saved successfully", {
          appearance: "success",
        });
        if (path) {
          history.push(path);
        }
      })
      .catch((err) => {
        addToast(
          err.response ? err.response.data.message : "Unable to connect",
          {
            appearance: "error",
          }
        );
        setBusy(false);
      });
  };

  const onClone = (state) => {
    // setBusy(true);

    var clone = {
      ...state,
      deviceId: 0,
      name: state.name + " clone",
      branch: null,
      deviceGroup: null,
    };

    history.push(`device/new`, clone);

    // banjosApi
    //   .post("device", clone)
    //   .then((res) => {
    //     setDevice((draft) => {
    //       draft.push({
    //         ...res.data,
    //         branch: state.branch,
    //         deviceGroup: state.deviceGroup,
    //       });
    //     });
    //     setBusy(false);
    //     addToast("Device cloned successfully", {
    //       appearance: "success",
    //     });
    //   })
    //   .catch((err) => {
    //     addToast(err.response ? err.response.data.message : "Unable to connect", {
    //       appearance: "error",
    //     });
    //     setBusy(false);
    //   });
  };

  const onUpdate = (id) => {
    setBusy(true);
    banjosApi
      .put(`device/${id}`, state)
      .then((res) => {
        setBusy(false);
        addToast("Device saved successfully", {
          appearance: "success",
        });
        history.push(`/admin/device`);
      })
      .catch((err) => {
        addToast(
          err.response ? err.response.data.message : "Unable to connect",
          {
            appearance: "error",
          }
        );
        setBusy(false);
      });
  };

  const onDelete = (id) => {
    setBusy(true);
    banjosApi
      .delete(`device/${id}`)
      .then((res) => {
        setBusy(false);
        setDevice((draft) => {
          return draft.filter((c) => c.deviceId !== id);
        });
      })
      .catch((err) => {
        addToast(
          err.response ? err.response.data.message : "Unable to connect",
          {
            appearance: "error",
          }
        );
        setBusy(false);
      });
  };
  const GetDeviceInfo = (accessToken) => {
    return banjosApi.get(`device/info/${accessToken}`);
  };
  const onRegister = (deviceId, deviceInfo, forceReload) => {
    setBusy(true);
    banjosApi
      .post(`device/register`, {
        deviceId,
        deviceInfo: JSON.stringify(deviceInfo),
      })
      .then((res) => {
        if (res.status === 200) {
          const deviceInfo = {
            name: res.data.name,
            accessToken: res.data.accessToken,
            deviceId: res.data.deviceId,
            branchId: res.data.branchId,
            groupId: res.data.groupId,
            timeZone: res.data.branch.timeZone,
            orientation: res.data.orientation,
          };
          localStorage.setItem("DeviceInfo", JSON.stringify(deviceInfo));
          addToast("Device saved successfully", {
            appearance: "success",
          });
          if (forceReload === true) {
            window.location.reload();
          } else {
            history.push("/");
          }
        }
        setBusy(false);
      })
      .catch((err) => {
        addToast(
          err.response ? err.response.data.message : "Unable to connect",
          {
            appearance: "error",
          }
        );
        setBusy(false);
      });
  };

  useEffect(() => {
    if (load) {
      onLoad();
    }
  }, []);

  useEffect(() => {
    if (deviceId > 0) {
      setBusy(true);
      banjosApi
        .get(`device/${deviceId}`)
        .then((res) => {
          setState((draft) => res.data);
          setBusy(false);
        })
        .catch((err) => {
          addToast(
            err.response ? err.response.data.message : "Unable to connect",
            {
              appearance: "error",
            }
          );
          setBusy(false);
        });
    }
  }, [deviceId]);

  useEffect(() => {
    if (branchId > 0) {
      setBusy(true);
      banjosApi
        .get(`device/GetDevicesbyBranch/${branchId}`)
        .then((res) => {
          setDevice((draft) => res.data);
          setBusy(false);
        })
        .catch((err) => {
          addToast(
            err.response ? err.response.data.message : "Unable to connect",
            {
              appearance: "error",
            }
          );
          setBusy(false);
        });
    }
    return () => {};
  }, [branchId]);

  return {
    device,
    setDevice,
    onSave,
    onUpdate,
    onDelete,
    onClone,
    onRegister,
    isBusy,
    state,
    setState,
    GetDeviceInfo,
  };
};
