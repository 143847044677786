import React from "react";
import { GENERAL_PATH } from "../../../api/api-config";
import Logo from "../../../assets/banjos_logo_g.png";
import { useMainStore } from "../../../Store/MainStore";
import BFIT_LOGO from "../../../assets/images/bfit_logo.svg";
export const Loading = (props) => {
  const { AppState } = useMainStore();
  return (
    <>
      <div className="d-flex justify-content-center">
        <div className="custom-loader">
          <>
            <img
              src={
                AppState.config?.logo
                  ? GENERAL_PATH + AppState.config.logo
                  : BFIT_LOGO
              }
              alt="bfit logo"
              width="200"
            />
            {props.text && <h4>{props.text}</h4>}
          </>
          <div className="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    </>
  );
};
