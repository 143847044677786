import React from "react";
import { useEffect } from "react";

export const Reset = () => {
  useEffect(() => {
    localStorage.removeItem("DeviceInfo");
    window.location.href = "/";
  }, []);
  return <>Reloading</>;
};
