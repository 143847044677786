// export const BASE_URL = `${window.location.protocol}//${window.location.host}/`;
// export const BASE_URL = "https://banjosau.azurewebsites.net/";
// export const BASE_URL = "http://20.188.224.142:2468/";
// export const BASE_URL = "https://localhost:44336/";

import { getTenantId } from "../helper/Common";

//Staging
export const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY;
export const BASE_URL = process.env.REACT_APP_BASE_URL;

//banjos-production
// export const PUSHER_KEY = "134ef567825d9ef54d0d";
// export const BASE_URL = "https://banjos.bfitds.com/";

//banjos-production
// export const PUSHER_KEY = "2ea446b4d2eee52d8472";
// export const BASE_URL = "https://cloudifi.bfitds.com/";

//signage-demo
// export const PUSHER_KEY = "2911529b7b4f1ed7c649";
// export const BASE_URL = "http://40.127.77.179:4321/";

//Test
// export const PUSHER_KEY = "b33518ef0e57f16fea78";

//liveat-production
// export const PUSHER_KEY = "2c516e0f4a40ed39cd0c";
// export const BASE_URL = "https://liveat.bfitds.com/";
export const AZURE_STORAGE = `https://storage.bfitds.com/${getTenantId()}/`;
export const API_ENDPOINT = BASE_URL + "api/";
export const CONTENT_URL = AZURE_STORAGE + "contents/";
export const IMAGE_URL = CONTENT_URL + "Images/";
export const GENERAL_PATH = AZURE_STORAGE + "general/";
