import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { forwardRef, useEffect, useCallback } from "react";
// material
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = "", ...other }, ref) => {
  return (
    <Box ref={ref} {...other}>
      {/* <Helmet titleTemplate="bfitds - %s">
        <title>{title}</title>
      </Helmet> */}
      {children}
    </Box>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Page;
