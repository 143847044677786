import React from "react";

export const useCacheStorage = () => {
  const cacheData = (url, cacheName, data) => {
    // console.log("data", data);
    if ("caches" in window) {
      caches.open(cacheName).then((cache) => {
        cache.put(url, data);
      });
    }
  };

  return { cacheData };
};
