import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useImmer } from "use-immer";
import { useSettings } from "../hooks/useSettings";
import { AppContext } from "./AppContext";
export const MainStore = (props) => {
  const { settings } = useSettings();
  const initial = {
    isAuthenticated: false,
    user: "",
    token: "",
    error: null,
    config: {}
  };

  const localData = JSON.parse(localStorage.getItem("localData"));
  const [AppState, setAppState] = useImmer(localData || initial);

  useEffect(() => {

    setAppState(draft => {
      draft.config = settings;
    });
    
    return () => {

    }
  }, [settings])

  useEffect(() => {
    localStorage.setItem("localData", JSON.stringify(AppState));
    return () => { };
  }, [AppState]);

  return (
    <AppContext.Provider value={{ AppState, setAppState }}>
      {props.children}
    </AppContext.Provider>
  );
};

export const useMainStore = () => useContext(AppContext);
