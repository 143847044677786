import { useRef, useCallback } from "react";

export const useFocusNext = () => {
  const controls = useRef([]);

  const handler = (event) => {
    // console.log("event", event);
    //LG 39- RIGHT, 37-LEFT

    if (event.keyCode === 37) {
      // Required if the controls can be reordered
      controls.current = controls.current
        .filter((control) => document.body.contains(control))
        .sort((a, b) =>
          a.compareDocumentPosition(b) & Node.DOCUMENT_POSITION_FOLLOWING
            ? -1
            : 1
        );

      const index = controls.current.indexOf(event.target);
      if (index > 0) {
        const next = controls.current[index - 1];
        next && next.focus();
      }

      // IE 9, 10
      event.preventDefault();
    } else if (event.keyCode === 39) {
      // Required if the controls can be reordered
      controls.current = controls.current
        .filter((control) => document.body.contains(control))
        .sort((a, b) =>
          a.compareDocumentPosition(b) & Node.DOCUMENT_POSITION_FOLLOWING
            ? -1
            : 1
        );

      const index = controls.current.indexOf(event.target);
      // console.log("Index", index);
      const next = controls.current[index + 1];
      next && next.focus();

      // IE 9, 10
      event.preventDefault();
    }
    // if (event.keyCode === 13) {
    //   // Required if the controls can be reordered
    //   controls.current = controls.current
    //     .filter((control) => document.body.contains(control))
    //     .sort((a, b) =>
    //       a.compareDocumentPosition(b) & Node.DOCUMENT_POSITION_FOLLOWING
    //         ? -1
    //         : 1
    //     );

    //   const index = controls.current.indexOf(event.target);
    //   const next = controls.current[index + 1];
    //   next && next.focus();

    //   // IE 9, 10
    //   event.preventDefault();
    // }
  };

  return useCallback((element) => {
    if (element && !controls.current.includes(element)) {
      controls.current.push(element);
      element.addEventListener("keydown", handler);
    }
  }, []);
};
