import { Redirect, Route, Switch } from "react-router-dom";
import { Signage } from "./views/user/Signage";
import { Setup } from "./views/user/Setup";
import { ToastProvider } from "react-toast-notifications";
import { MainStore } from "./Store/MainStore";
import { ErrorBoundary } from "react-error-boundary";
import BanjosLogo from "./assets/banjos_logo_g.png";
import { DeviceInfo } from "./helper/Common";
import * as Sentry from "@sentry/react";
import { Offline } from "@sentry/integrations";
import { Info } from "./views/user/Info";
import { Reset } from "./views/user/Reset";
import { Auth } from "./views/user/Auth";
import { useAuth } from "./hooks/useAuth";
import BFIT_LOGO from "./assets/images/bfit_logo.svg";
import ThemeConfig from "./layout/theme";

function App() {
  // var _rollbarConfig = {
  //   accessToken: "e107c4c612534282b6e6c396d95b937e",
  //   captureUncaught: true,
  //   captureUnhandledRejections: true,
  //   enabled: process.env.NODE_ENV !== "development",
  //   payload: {
  //     environment: "banjos-production",
  //     person: {
  //       id: DeviceInfo().deviceId, // required
  //     },
  //     client: { deviceInfo: DeviceInfo() },
  //   },
  // };
  // const rollBar = new Rollbar(_rollbarConfig);
  // // Set the onSendCallback() function
  // rollBar.configure({
  //   onSendCallback: function (isUncaught, args, payload) {
  //     //do something
  //     //console.log("//do something");
  //   },
  // });

  Sentry.setContext("deviceInfo", DeviceInfo());
  Sentry.init({
    dsn: "https://a0e669fb5f574f099645f127976f3c6f@o801338.ingest.sentry.io/5802562",
    integrations: [new Offline()],
  });

  function ErrorFallback({ error, resetErrorBoundary }) {
    return (
      <div className="container setup-page">
        <div className="row justify-content-center">
          <div className="col-md-4 setup-card">
            <div className="card border-0">
              <div className="card-body">
                <div className="text-center">
                  <img src={BFIT_LOGO} width="150" alt="Logo" />
                  <div role="alert">
                    <p>Something went wrong:</p>
                    <p>{error.message}</p>
                    <button
                      onClick={resetErrorBoundary}
                      className="btn btn-primary"
                    >
                      Try again
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <ThemeConfig>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ToastProvider
          autoDismiss
          autoDismissTimeout={6000}
          placement="bottom-right"
        >
          <MainStore>
            <Switch>
              <Route path="/device/config" component={Setup} exact></Route>
              <Route path="/config/?">
                <Redirect to="/" />
              </Route>
              <Route exact path="/login" component={Auth} />
              <Route path="/info" component={Info} />
              <Route path="/reset" component={Reset} />
              {/* <Route
                exact
                path="/:playerCode"
                component={Auth}
                // render={(props) => {
                //   const playerCode = props.match.params.playerCode;
                //   return <Redirect to={`login/${playerCode}`} />;
                // }}
              /> */}
              <Route path="/" component={Signage}></Route>
            </Switch>
          </MainStore>
        </ToastProvider>
      </ErrorBoundary>
    </ThemeConfig>
  );
}

export default App;
